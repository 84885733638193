/* 
  .gtx-section
  --primary
  --small
  --page
  --footer
*/

.gtx-section {
  z-index: 10;
  background-color: transparent;
  background: radial-gradient(
    circle 2px,
    rgba(193, 192, 192, 0.3) 95%,
    transparent
  );

  background-size: calc(100vw / 25) calc(100vw / 25);
}

.gtx-section {
  width: calc(100% - 200px);
  padding-top: 50px;
  padding-bottom: 200px;
  padding-left: 100px;
  padding-right: 100px;
}

.gtx-section--small {
  padding-top: 50px;
  padding-bottom: 100px;
}

.gtx-section--primary {
  padding-top: 120px;
  background-color: rgb(93, 62, 247, 1);
}

.gtx-section--overflow + .gtx-section {
  margin-top: -360px;
  padding-top: 300px;
}

.gtx-section--page {
  height: 100vh;
}

.gtx-section--footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

/* 
  .gtx-navbar
  __options
  --footer
*/

.gtx-navbar {
  height: 68px;
  display: flex;
  justify-content: space-between;
}

.gtx-navbar__options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
}

.gtx-navbar__options li {
  margin: 0 22px 0 22px;
}

.gtx-navbar__options li > a {
  font-size: 1em;
  color: rgb(26, 18, 58);
  font-family: "Space Mono";
  font-weight: 700;
  text-decoration: none;
}

.gtx-navbar__options li:first-child {
  margin-left: 0;
}

.gtx-navbar__options li:last-child {
  margin-right: 0;
}

.gtx-navbar__options li > a:hover {
  color: white;
  text-decoration: underline;
}

.gtx-navbar__options li > a:visited {
  color: none;
}

.gtx-navbar--footer {
  justify-content: flex-start;
}

.gtx-navbar--footer > .gtx-navbar__options {
  align-items: flex-end;
}

.gtx-navbar--footer > .gtx-navbar__options li > a {
  font-weight: 300;
  font-family: "Space Mono";
  line-height: 2em;
  font-size: 1.2em;
  color: white;
}

.gtx-navbar--footer > .gtx-navbar__options li > a:hover {
  color: rgb(26, 18, 58);
  font-weight: 700;
}

/* 
  .gtx-intro
*/

.gtx-intro {
  display: flex;
  justify-content: space-between;
  padding-top: 200px;
}

/* 
  gtx-project-box 
  __container
  __index
  __tag
  __title
*/

.gtx-project-box__container {
  display: flex;
  justify-content: space-between;
}

.gtx-project-box__container:not(:first-child) {
  margin-top: 30px;
}

.gtx-project-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 27px 0 27px;
  min-height: fit-content;
  height: 33vw;
  margin-right: 45px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: solid 18px white;
}

.gtx-project-box:hover {
  border-color: rgba(26, 18, 58, 0.9);
}

.gtx-project-box:hover > .gtx-project-box__index {
  color: rgba(26, 18, 58, 0.9);
  transition: color 1s;
}

.gtx-project-box:last-child {
  margin-right: 0;
}

.gtx-project-box:nth-child(2n) {
  margin-top: 15%;
}

.gtx-project-box:nth-child(3n) {
  margin-top: 5%;
}

.gtx-project-box__index {
  flex: 1;
  margin-top: 15%;
  align-self: flex-end;
  width: 10px;
  font-family: "Space Mono";
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.5em;
  color: rgba(255, 255, 255, 0.9);
}

.gtx-project-box__tag {
  font-family: "Space Mono";
  font-size: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
  color: #1b133c;
}

.gtx-project-box__title {
  margin: 13px 0 44px 0;
  font-family: "Oswald";
  font-size: 2.5em;
  line-height: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  width: 70%;
}

/* 
  gtx-instagram-grid 
  gtx-instagram-grid__squirifier
*/

.gtx-instagram-grid {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 50px;
}

.gtx-instagram-grid:first-child {
  margin-right: 50px;
}

/* Hacky trick from https://codepen.io/ItScofield/pen/PNVZoQ */
.gtx-instagram-grid__square {
  flex: 1 1 calc(50% - 50px);
  max-width: calc(50% - 25px);
  position: relative;
  margin-top: 50px;
}

.gtx-instagram-grid__square--full {
  flex: 1 1 100%;
  max-width: none;
  margin-right: 0;
}

.gtx-instagram-grid__square:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.gtx-instagram-grid__square img {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gtx-markdown *:is(h1, h2, h3, h4, h5, h6) {
  font-family: "Oswald";
}

.gtx-markdown *:is(pre, code, a, p, em, li) {
  font-family: "Space Mono" !important;
}

.gtx-markdown *:is(a) {
  color: #5d3ef7 !important;
}

/* 
  gtx-photo-grid
*/
.gtx-photo-grid ul {
  display: flex;
  flex-wrap: wrap;
}

.gtx-photo-grid li {
  padding: 5px;
  height: 40vh;
  flex-grow: 1;
}

.gtx-photo-grid img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

/* Mobile queries */
@media only screen and (min-width: 320px) and (max-width: 479px) {
  /* gtx-section:mobile */
  .gtx-section {
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }

  .gtx-section--primary {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .gtx-section--footer {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    height: 300px;
  }

  .gtx-section--overflow + .gtx-section {
    margin-top: -300px;
    padding-top: 100px;
  }

  /* gtx-navbar:mobile */
  .gtx-navbar {
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .gtx-navbar__options li {
    margin: 0 10px 0 10px;
  }

  .gtx-navbar--footer__container {
    align-items: flex-end;
  }

  .gtx-navbar--footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: fit-content;
  }

  .gtx-navbar--footer > .gtx-navbar__options {
    align-items: flex-end;
  }

  /* gtx-intro:mobile */
  .gtx-intro {
    justify-content: space-around;
    padding-top: 50px;
    flex-direction: column;
    height: 60vh;
  }

  /* gtx-project-box:mobile */

  .gtx-project-box {
    flex: 1 1 80%;
    margin: auto !important;
    width: 80%;
    margin-top: 50px !important;
  }

  .gtx-project-box__container {
    flex-direction: column;
    align-items: center;
  }

  /* .gtx-instagram-grid:mobile */
  .gtx-instagram-grid {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .gtx-instagram-grid:first-child {
    margin-right: 5px;
  }

  /* Hacky trick from https://codepen.io/ItScofield/pen/PNVZoQ */
  .gtx-instagram-grid__square {
    flex: 1 1 calc(50% - 5px);
    max-width: calc(50% - 5px);
    position: relative;
    margin-top: 5px;
  }

  .gtx-instagram-grid__square--full {
    flex: 1 1 100%;
    max-width: none;
    margin-right: 0;
  }

  /* gtx-photo-grid:mobile */
  .gtx-photo-grid li {
    padding: 5px;
    max-width: 100%;
    height: fit-content;
  }

  .gtx-photo-grid img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }
}

/* Mobile portrait + tablet queries */
@media only screen and (min-width: 480px) and (max-width: 991px) {
  /* gtx-section:tablet */
  .gtx-section {
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
  }

  .gtx-section--primary {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .gtx-section--overflow + .gtx-section {
    margin-top: -300px;
    padding-top: 80px;
  }

  .gtx-section--footer {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    height: 300px;
  }

  /* gtx-navbar:tablet */
  .gtx-navbar--footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: fit-content;
  }

  .gtx-navbar--footer > .gtx-navbar__options {
    align-items: flex-end;
  }

  .gtx-navbar--footer__container {
    margin-right: 0;
    align-items: flex-end;
  }

  /* gtx-intro:tablet */
  .gtx-intro {
    justify-content: space-around;
    padding-top: 50px;
    min-height: 60vh;
  }

  /* .gtx-project-box:tablet */
  .gtx-project-box__container {
    flex-wrap: wrap;
  }

  .gtx-project-box {
    flex: 1 1 33%;
    max-width: 33%;
  }

  .gtx-project-box:last-child {
    margin-right: auto;
  }

  .gtx-project-box:first-child {
    margin-top: 20px;
  }

  .gtx-project-box {
    height: 40vw;
  }

  .gtx-project-box__title {
    font-size: 2em;
    line-height: 1.2em;
    width: 100%;
  }

  /* gtx-photo-grid:tablet */
  .gtx-photo-grid li {
    padding: 5px;
    max-width: 100%;
    height: fit-content;
  }

  .gtx-photo-grid img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }
}

/* Wide+ screens */
@media only screen and (min-width: 1920px) {
  .gtx-section {
    width: calc(100% - 50vw);
    padding-right: 25vw;
    padding-left: 25vw;
  }

  /* .gtx-project-box:tablet */
  .gtx-project-box {
    min-height: 20vw;
  }
}

/* Custom behavior for project-box */
@media only screen and (min-width: 991px) and (max-width: 1150px) {
  /* .gtx-project-box:tablet */
  .gtx-project-box__container {
    flex-wrap: wrap;
  }

  .gtx-project-box {
    flex: 1 1 33%;
    max-width: 33%;
  }

  .gtx-project-box:last-child {
    margin-right: auto;
  }

  .gtx-project-box:first-child {
    margin-top: 20px;
  }

  .gtx-project-box {
    height: 40vw;
  }

  .gtx-project-box__title {
    font-size: 2em;
    line-height: 1.2em;
    width: 100%;
  }
}
