/* Elements are the atoms of the design */

/* 
  gtx-title 
  --main
  --quote

  Interacts with sections.
*/
.gtx-title {
  margin-bottom: 38px;
  font-weight: 700;
  font-family: "Space Mono";
  text-transform: uppercase;
}

.gtx-title--main {
  font-family: "Oswald";
  font-size: 5em;
  max-width: 500px;
  font-weight: 500;
  line-height: 1.2em;
  color: white;
}

.gtx-title--quote {
  display: block;
  font-family: "Oswald";
  font-size: 4em;
  font-weight: 400;
  line-height: 1.2em;
  max-width: 650px;
  color: black !important;
}

.gtx-section--primary .gtx-title {
  color: white;
}

.gtx-section--secondary .gtx-title {
  color: #5d3ef7;
}

/* 
  gtx-text 
  --main
  --hightlight
  --right
  --dark
*/
.gtx-text {
  font-weight: 300;
  line-height: 2em;
  font-family: "Space Mono";
  font-size: 1.2em;
  color: white;
}

.gtx-text--dark {
  color: #1b133c;
}

.gtx-text--main {
  max-width: 40%;
}

.gtx-text--right {
  text-align: right;
}

.gtx-text--hightlight {
  color: black;
  background: linear-gradient(0deg, white 46%, transparent 46%);
}

/* gtx-logo */
.gtx-logo {
  max-height: 4em;
}

/* gtx-isotype */

.gtx-isotype {
  max-height: 5em;
  height: auto;
}

/* gtx-button */
.gtx-button {
  background-color: #5d3ef7;
  color: white;
  text-align: center;
  padding: 10px 5px;
  cursor: pointer;
}

.gtx-button:hover {
  background-color: #4e3ef7;
  transition: color 200ms;
  transition-timing-function: ease;
}

/* gtx-input */

.gtx-input {
  font-family: "Space Mono";
  padding: 5px;
}

/* Mobile queries */
@media only screen and (min-width: 320px) and (max-width: 479px) {
  /* gtx-title:mobile */
  .gtx-title--main {
    font-size: 3em;
    max-width: 90%;
  }

  /* gtx-text:mobile */
  .gtx-text--main {
    max-width: 100%;
  }

  /* gtx-logo:mobile */
  .gtx-logo {
    max-height: 3em;
  }

  /* gtx-isotype:mobile */

  .gtx-isotype {
    max-height: 3em;
    opacity: 0.5;
  }
}

/* Mobile portrait + tablet queries */
@media only screen and (min-width: 480px) and (max-width: 991px) {
  /* gtx-title:mobile */
  .gtx-title--main {
    font-size: 3em;
    max-width: 32%;
  }
}
