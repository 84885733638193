.gtx-flex-container {
  display: flex;
}

.gtx-flex-left {
  justify-content: safe;
}

.gtx-flex-row {
  flex-direction: row;
}

.gtx-flex-row--reverse {
  flex-direction: row-reverse;
}

.gtx-flex-column {
  flex-direction: column;
}

.gtx-flex-column--reverse {
  flex-direction: column-reverse;
}

.gtx-block {
  display: block;
}

.gtx-invisible-anchor:hover {
  text-decoration: none;
}

.gtx-invisible-anchor:visite {
  text-decoration: none;
  color: none;
}

.gtx-relative {
  position: relative;
}

.gtx-separator {
  margin-top: 20px;
}
