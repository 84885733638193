/* Main definitions */
body {
  font-size: 16px;
  font-family: "Oswald", "Space Mono";
  width: 100vw;
}

/* Mobile */
@media only screen and (min-width: 300px) and (max-width: 479px) {
  body {
    font-size: 14px;
  }
}

/* Mobile portrait + tablet */
@media only screen and (min-width: 480px) and (max-width: 991px) {
  body {
    font-size: 14px;
  }
}

/* Remove scrollbar (at least from webkit) */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

a {
  font-weight: 700;
  color: #1b133c;
}

a:hover {
  color: white;
  transition: color 700ms;
  transition-timing-function: ease;
}

a::visited {
  color: white;
}
